import actions from './propertyActions'
import { SELECT_NICHE } from '@/forms/schemas/generators'
import { REGIONS } from '@/forms/selectOptions'

const schema = [
  {
    component: 'div',
    class: 'tw-my-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-3 tw-gap-4',
    children: [
      {
        type: 'text',
        name: 'reference',
        label: 'Referentie',
        placeholder: 'Referentie',
        validation: 'required',
        showRequiredLabel: true,
        outerClass: 'tw-m-0'
      },
      { ...SELECT_NICHE, outerClass: 'tw-m-0' },
      {
        type: 'autocomplete',
        autoCompleteType: 'street',
        name: 'street',
        label: 'Straat',
        placeholder: 'Zoek een straat',
        outerClass: 'tw-m-0',
        '@callback': 'streetSelected'
      },
      {
        component: 'div',
        class: 'tw-grid md:tw-grid-cols-2 tw-gap-4',
        children: [
          {
            type: 'text',
            name: 'number',
            label: 'Nummer',
            placeholder: 'Nummer',
            outerClass: 'tw-m-0'
          },
          {
            type: 'text',
            name: 'box',
            label: 'Bus',
            placeholder: 'Bus',
            validation: 'max:10,length',
            outerClass: 'tw-m-0'
          }
        ]
      },
      {
        type: 'autocomplete',
        autoCompleteType: 'city',
        name: 'city',
        label: 'Plaats',
        placeholder: 'Zoek op postcode of plaatsnaam',
        validation: 'required',
        showRequiredLabel: true,
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'region',
        label: 'Gewest',
        placeholder: 'Selecteer regio',
        options: REGIONS,
        outerClass: 'tw-m-0'
      }
    ]
  }
]

export default {
  heading: 'Algemene gegevens',
  schema,
  actions
}
